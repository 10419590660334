@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  @apply relative rounded-lg bg-white bg-opacity-5 hover:bg-opacity-10 transition-colors duration-100 text-white text-xl px-4 py-6 sm:py-12 border border-purple-500 border-opacity-10 hover:border-opacity-20 ring-purple-400 text-center font-medium cursor-pointer
}

.card > p > span {
  @apply font-normal text-purple-500
}

.card em {
  @apply opacity-0 transition-opacity duration-100 absolute bottom-1 sm:bottom-6 right-0 text-sm text-purple-500 font-normal w-full text-center not-italic hidden md:inline-block
}

.card:hover em {
  @apply md:opacity-100
}

.card em svg {
  @apply inline-block w-4 h-4 mr-1 mt-[-2px]
}

#submit-prompt {
  @apply w-full rounded-lg font-semibold text-white text-center p-4 bg-gradient-to-br from-indigo-800 to-purple-800 mt-6 hover:from-indigo-700 hover:to-purple-700
}

#submit-prompt.loading {
  @apply opacity-75 hover:from-indigo-800 hover:to-purple-800 transition-opacity duration-1000
}

#prompt {
  @apply w-full rounded-lg bg-white bg-opacity-5 focus:bg-opacity-10 transition-colors duration-100 text-white p-4 border border-purple-500 border-opacity-10 focus:border-opacity-20 ring-purple-400 h-28 placeholder-white placeholder-opacity-30
}

#prompt.loading {
  @apply opacity-75 transition-opacity duration-1000
}

#prompt-container.loading {
  @apply min-h-0 h-0 transition-all duration-1000 overflow-hidden delay-1000 opacity-0
}

#random-idea #random-idea-loading {
  display: none;
}

#random-idea #random-idea-shuffle {
  display: inline-block;
}

#random-idea.loading #random-idea-loading {
  display: inline-block;
}

#random-idea.loading #random-idea-shuffle {
  display: none;
}
