*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-4 {
  height: 1rem;
}

.h-full {
  height: 100%;
}

.h-1 {
  height: .25rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-6 {
  width: 1.5rem;
}

.w-4 {
  width: 1rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-0 {
  width: 0;
}

.max-w-xl {
  max-width: 36rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#080A1B\] {
  --tw-gradient-from: #080a1b;
  --tw-gradient-to: #080a1b00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-to: #6366f100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-300 {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-to: #a5b4fc00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-200 {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-to: #c7d2fe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#200043\] {
  --tw-gradient-to: #200043;
}

.to-purple-500 {
  --tw-gradient-to: #a855f7;
}

.to-purple-300 {
  --tw-gradient-to: #d8b4fe;
}

.to-purple-200 {
  --tw-gradient-to: #e9d5ff;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-2 {
  padding: .5rem;
}

.p-12 {
  padding: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-transparent {
  color: #0000;
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-75 {
  opacity: .75;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-500 {
  transition-duration: .5s;
}

.card {
  cursor: pointer;
  border-width: 1px;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
  border-radius: .5rem;
  padding: 1.5rem 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

.card:hover {
  --tw-border-opacity: .2;
  --tw-bg-opacity: .1;
}

@media (min-width: 640px) {
  .card {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.card > p > span {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
  font-weight: 400;
}

.card em {
  width: 100%;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
  opacity: 0;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: none;
  position: absolute;
  bottom: .25rem;
  right: 0;
}

@media (min-width: 640px) {
  .card em {
    bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .card em {
    display: inline-block;
  }

  .card:hover em {
    opacity: 1;
  }
}

.card em svg {
  height: 1rem;
  width: 1rem;
  margin-top: -2px;
  margin-right: .25rem;
  display: inline-block;
}

#submit-prompt {
  width: 100%;
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
  --tw-gradient-from: #3730a3;
  --tw-gradient-to: #3730a300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #6b21a8;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  margin-top: 1.5rem;
  padding: 1rem;
  font-weight: 600;
}

#submit-prompt:hover {
  --tw-gradient-from: #4338ca;
  --tw-gradient-to: #4338ca00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #7e22ce;
}

#submit-prompt.loading {
  opacity: .75;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#submit-prompt.loading:hover {
  --tw-gradient-from: #3730a3;
  --tw-gradient-to: #3730a300;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #6b21a8;
}

#prompt {
  height: 7rem;
  width: 100%;
  border-width: 1px;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: .05;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
}

#prompt::placeholder {
  color: rgb(255 255 255 / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .3;
}

#prompt {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#prompt:focus {
  --tw-border-opacity: .2;
  --tw-bg-opacity: .1;
}

#prompt.loading {
  opacity: .75;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#prompt-container.loading {
  height: 0;
  min-height: 0;
  opacity: 0;
  transition: all 1s cubic-bezier(.4, 0, .2, 1) 1s;
  overflow: hidden;
}

#random-idea #random-idea-loading {
  display: none;
}

#random-idea #random-idea-shuffle, #random-idea.loading #random-idea-loading {
  display: inline-block;
}

#random-idea.loading #random-idea-shuffle {
  display: none;
}

.hover\:text-violet-300:hover {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-4 {
    gap: 1rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:max-w-\[1100px\] {
    max-width: 1100px;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=index.1ca85f09.css.map */
